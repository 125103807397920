export default[
  {
    path: '/administracion/ajuste-negativo',
    name: 'administracion-ajuste-negativo',
    component: () => import('@/modules/administracion/ajuste/layouts/AjusteNegativoLayout.vue'),
    meta: {
      slug: 'Ver ajustes negativos',
    },
  },
  {
    path: '/administracion/ajuste-positivo',
    name: 'administracion-ajuste-positivo',
    component: () => import('@/modules/administracion/ajuste/layouts/AjustePositivoLayout.vue'),
    meta: {
      slug: 'Ver ajustes positivos',
    },
  },
  {
    path: '/administracion/ajuste-negativo/nuevo',
    name: 'administracion-nuevo-ajuste-negativo',
    component: () => import('@/modules/administracion/ajuste/components/NuevoAjusteNegativo.vue'),
    meta: {
      navActiveLink: 'administracion-ajuste-negativo',
      slug: 'Nuevo ajuste negativo',
    },
  },
  {
    path: '/administracion/ajuste-positivo/nuevo',
    name: 'administracion-nuevo-ajuste-positivo',
    component: () => import('@/modules/administracion/ajuste/components/NuevoAjustePositivo.vue'),
    meta: {
      navActiveLink: 'administracion-ajuste-positivo',
      slug: 'Nuevo ajuste positivo',
    },
  },
]