export default [
    {
        path: '/cotizaciones',
        name: 'cotizaciones',
        component: () => import('@/modules/cotizacion/layouts/CotizacionLayout.vue'),
        meta: {
            slug: 'cotizacion-ver',
        },
    },
    {
        path: '/cotizaciones/nueva-cotizacion',
        name: 'cotizaciones-nueva-cotizacion',
        component: () => import('@/modules/cotizacion/layouts/NuevaCotizacionLayout.vue'),

        meta: {
            slug: 'cotizacion-registrar',
        },
    },
]