export default [
    {
        path: '/reportes/inventario/compras',
        name: 'reportes-inventario-compras',
        component: () => import('@/modules/reportes/inventario/layouts/ComprasLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/ventas',
        name: 'reportes-inventario-ventas',
        component: () => import('@/modules/reportes/inventario/layouts/VentasLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/movimiento-general',
        name: 'reportes-inventario-movimiento-general',
        component: () => import('@/modules/reportes/inventario/layouts/MovimientoGeneralLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/catalogo',
        name: 'reportes-inventario-catalogo',
        component: () => import('@/modules/reportes/inventario/layouts/CatalogoLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/existencia',
        name: 'reportes-inventario-existencia',
        component: () => import('@/modules/reportes/inventario/layouts/ExistenciaLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/categoria',
        name: 'reportes-inventario-categoria',
        component: () => import('@/modules/reportes/inventario/layouts/CategoriaLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/linea-marca',
        name: 'reportes-inventario-linea-marca',
        component: () => import('@/modules/reportes/inventario/layouts/MarcaLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/movimiento-general-usuario',
        name: 'reportes-inventario-movimiento-general-usuario',
        component: () => import('@/modules/reportes/inventario/layouts/MovimientoGeneralUsuarioLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/kardex',
        name: 'reportes-inventario-movimiento-kardex',
        component: () => import('@/modules/reportes/inventario/layouts/MovimientoKadexLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/kardex-fechas',
        name: 'reportes-inventario-movimiento-kardex-fechas',
        component: () => import('@/modules/reportes/inventario/layouts/MovimientoKadexFechasLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/inventario-general',
        name: 'reportes-inventario-inventario-general',
        component: () => import('@/modules/reportes/inventario/layouts/InventarioGeneralLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
    {
        path: '/reportes/inventario/vencimientos',
        name: 'reportes-inventario-vencimientos',
        component: () => import('@/modules/reportes/inventario/layouts/VencimientoLayout.vue'),
        meta: {
            slug: 'reportes_inventarios-ver',
        },
    },
]
