import store from '../index'
//import axios from 'axios'
import axios from "@axios";
//import axiosIns from '@/libs/axios'
store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'auth/SET_TOKEN':
            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
                localStorage.setItem('token', mutation.payload)
            } else {
                axios.defaults.headers.common['Authorization'] = null
                localStorage.removeItem('token')
            }
            break;
        case 'auth/SET_USER':
            if (mutation.payload) {
                localStorage.setItem('permisos', mutation.payload.permisos)
            } else {
                axios.defaults.headers.common['Authorization'] = null
                localStorage.removeItem('permisos')
            }
            break;
        case 'auth/SET_SEDE_ID':
            if (mutation.payload) {
                localStorage.setItem('sede_id', mutation.payload)
            }
            break;
        case 'auth/SET_CONFIG':
            if (mutation.payload) {
                document.title = mutation.payload.nombre_empresa
                localStorage.setItem('config', JSON.stringify(mutation.payload))
            }
            break;
        default:
            break;
    }

})