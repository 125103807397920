export default[
    {
      path: '/ajuste-negativo',
      name: 'ajuste-negativo',
      component: () => import('@/modules/ajuste_negativo/layouts/AjusteNegativoLayout.vue'),
      meta: {
        slug: 'ajuste_negativo-ver',
      },
    },
    {
      path: '/ajuste-negativo/nuevo',
      name: 'ajuste-negativo-nuevo',
      component: () => import('@/modules/ajuste_negativo/layouts/NuevoAjusteNegativoLayout.vue'),
      
      meta: {
        slug: 'ajuste_negativo-registrar',
      },
  },
]