import { mapGetters } from "vuex";
import store from '@/store'
export default {
  computed: {
    ...mapGetters({
      mixing: 'auth/authenticated'
    }),
  },
  methods: {
    $hascan(name) {
      return this.mixing?.permisos?.indexOf(name) !== -1;
    },
    $caja() {
      return this.mixing.apertura !== this.mixing.corte;
    },
    $aperturacaja() {
      return this.mixing.apertura == true;
    },
    $cortecaja() {
      return this.mixing.corte == true;
    },
  },
};