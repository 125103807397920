export default[
  {
      path: '/ventas',
      name: 'ventas',
      component: () => import('@/modules/venta/layouts/VentaLayout.vue'),
      meta: {
        slug: 'venta-ver',
      },
  },
  {
    path: '/ventas/punto-venta',
    name: 'ventas-punto-venta',
    component: () => import('@/modules/venta/layouts/NuevaVentaVendedorLayout.vue'),
    // component: () => import('@/modules/venta/layouts/PuntoVentaLayout.vue'),
    meta: {
      slug: 'venta-registrar',
    },
},
]