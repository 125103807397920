import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',  
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: process.env.VUE_APP_URL_API,
  // baseURL: 'http://localhost:3000/api/',
  // baseURL: 'https://sifacturoconfigdev.esam.edu.bo/api/',
  headers: {'X-Requested-With': 'XMLHttpRequest'},
  headers: {'X-Custom-Header': 'foobar'},
  headers: {'Access-Control-Allow-Origin': '*'},
  // headers: {'Access-Control-Allow-Headers': 'Content-Type, Authorization'},
  headers: {'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}
})

// Vue.prototype.$http = axiosIns

export default axiosIns
