export default [
    {
        path: '/pedidos',
        name: 'pedidos',
        component: () => import('@/modules/pedido/layouts/PedidoLayout.vue'),
        meta: {
            slug: 'pedido-ver',
        },
    },
    {
        path: '/pedidos/nuevo-pedido',
        name: 'pedidos-nuevo-pedido',
        component: () => import('@/modules/pedido/layouts/NuevoPedidoLayout.vue'),

        meta: {
            slug: 'pedido-registrar',
        },
    },
]