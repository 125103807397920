//import axios from 'axios'
import axios from "@axios";
import Vue from "vue";
//import axiosIns from '@/libs/axios'

export default ({
    namespaced: true,
    state: {
        token: null,
        user: null,
        sede_id: null || localStorage.getItem('sede_id'),
        sedes: [],
        config: null || JSON.parse(localStorage.getItem("config"))  
    },
    getters: {
        authenticated(state) {
            return { ...state.user, sede_id: state.sede_id, sedes: state.sedes, config: state.config }
        },
        user(state) {
            return state.user
        },
        // sedeId(state) {
        //     return state.sede_id
        // },
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, data) {
            state.user = data
        },
        SET_SEDE_ID(state, sedeId) {
            state.sede_id = sedeId
        },
        SET_SEDES(state, sedes) {
            state.sedes = sedes?.map((g) => ({
                value: g.id,
                text:
                    `${g.municipio.departamento.nombre} ${g.municipio.nombre} ${g.numero_sede} - ${g.sucursal.numero_sucursal}`,
            }));
        },
        SET_CONFIG(state, config) {
            state.config = config;
        },
    },
    actions: {
        async signIn({ dispatch }, credentials) {
            let response = await axios.post('auth/login', credentials)

            return dispatch('attempt', response.data.token)
        },
        async passwordReset({}, passwords) {
           await axios.post('auth/password-reset', passwords)
        },
        async attempt({ commit, state, dispatch }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }

            if (!state.token) {
                return
            }
            
            const params = state.sede_id ? { sede_id: parseInt(state.sede_id) } : {}
            
            await dispatch('peticionCambiarSede', params)
        },
        signOut({ commit }) {
            commit('SET_USER', null)
            commit('SET_SEDE_ID', null)
            commit('SET_TOKEN', null)
            commit('SET_SEDES', null)
            commit('SET_CONFIG', null)
            // return axios.post('logout').then(()=>{
            //     commit('SET_TOKEN',null)
            //     commit('SET_USER',null)
            // })
        },
        async peticionCambiarSede({ commit }, params) {
            try {
                const [response1, response2] = await Promise.all([axios.post('auth/cambiar-sede', params), axios.get('sedes/usuario')])
                commit('SET_USER', response1.data)
                commit('SET_SEDE_ID', response1.data.sede_id)
                commit('SET_TOKEN', response1.data.token)
                commit('SET_SEDES', response2.data)
                commit("SET_CONFIG", response1.data.config);
            } catch (err) {
                commit('SET_USER', null)
                commit('SET_SEDE_ID', null)
                commit('SET_TOKEN', null)
                commit('SET_SEDES', null)
                commit('SET_CONFIG', null)
                console.error(err);
            }
        }
    },
})