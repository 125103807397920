export default [
    {
        path: '/reportes/compras/compras-generales',
        name: 'reportes-compras-generales',
        component: () => import('@/modules/reportes/compras/layouts/ReporteComprasGeneralesLayout.vue'),
        meta: {
            slug: 'reportes_compras-ver',
        },
    },
    {
        path: '/reportes/compras/compras-detalladas',
        name: 'reportes-compras-detalladas',
        component: () => import('@/modules/reportes/compras/layouts/ReporteComprasDetalladasLayout.vue'),
        meta: {
            slug: 'reportes_compras-ver',
        },
    },
    {
        path: '/reportes/compras/compras-detalladas-por-usuario',
        name: 'reportes-compras-detalladas-por-usuario',
        component: () => import('@/modules/reportes/compras/layouts/ReporteComprasDetalladasPorUsuarioLayout.vue'),
        meta: {
            slug: 'reportes_compras-ver',
        },
    },
    {
        path: '/reportes/compras/compras-detalladas-por-proveedor',
        name: 'reportes-compras-detalladas-por-proveedor',
        component: () => import('@/modules/reportes/compras/layouts/ReporteComprasDetalladasPorProveedorLayout.vue'),
        meta: {
            slug: 'reportes_compras-ver',
        },
    },
    {
        path: '/reportes/compras/compras-totales-mensuales',
        name: 'reportes-compras-totales-mensuales',
        component: () => import('@/modules/reportes/compras/layouts/ReporteComprasTotalesMensualesLayout.vue'),
        meta: {
            slug: 'reportes_compras-ver',
        },
    },
    {
        path: '/reportes/compras/compras-por-usuario',
        name: 'reportes-compras-por-usuario',
        component: () => import('@/modules/reportes/compras/layouts/ReporteComprasDetalladasPorUsuarioLayout.vue'),
        meta: {
            slug: 'reportes_usuario-ver',
        },
    },
]
