export default [
  {
    path: "/administracion/clientes",
    name: "administracion-clientes",
    component: () =>
      import("@/modules/administracion/cliente_proveedor/layouts/ClienteLayout.vue"),
    meta: {
      slug: "cliente-ver",
    },
  },
  {
    path: "/administracion/proveedores",
    name: "administracion-proveedores",
    component: () =>
      import("@/modules/administracion/cliente_proveedor/layouts/ProveedorLayout.vue"),
    meta: {
      slug: "proveedor-ver",
    },
  },
];
