export default[
    {
        path: '/compras',
        name: 'compras',
        component: () => import('@/modules/compra/layouts/CompraLayout.vue'),
        meta: {
          slug: 'compra-ver',
        },
    },
    {
      path: '/compras/nueva-compra',
      name: 'compras-nueva-compra',
      // component: () => import('@/modules/compra/layouts/NuevaCompraLayout.vue'),
      component: () => import('@/modules/compra/layouts/NuevaCompraVendedorLayout.vue'),
      meta: {
        slug: 'compra-registrar',
      },
    },
]