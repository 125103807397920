export default [
    {
        path: '/recepciones',
        name: 'recepciones',
        component: () => import('@/modules/recepcion-transferencia/layouts/RecepcionLayout.vue'),
        meta: {
            slug: 'recepcion-ver',
        },
    },
    {
        path: '/transferencias',
        name: 'transferencias',
        component: () => import('@/modules/recepcion-transferencia/layouts/TransferenciaLayout.vue'),
        meta: {
            slug: 'transferencia-ver',
        },
    },
    {
        path: '/transferencias/nueva-transferencia',
        name: 'transferencias-nueva-transferencia',
        component: () => import('@/modules/recepcion-transferencia/layouts/NuevaTransferenciaLayout.vue'),

        meta: {
            slug: 'transferencia-registrar',
        },
    },
]