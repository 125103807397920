export default [
    {
        path: '/reportes/ventas/ventas-generales',
        name: 'reportes-ventas-generales',
        component: () => import('@/modules/reportes/ventas/layouts/ReporteVentasGeneralesLayout.vue'),
        meta: {
            slug: 'reportes_ventas-ver',
        },
    },
    {
        path: '/reportes/ventas/ventas-detalladas',
        name: 'reportes-ventas-detalladas',
        component: () => import('@/modules/reportes/ventas/layouts/ReporteVentasDetalladasLayout.vue'),
        meta: {
            slug: 'reportes_ventas-ver',
        },
    },
    {
        path: '/reportes/ventas/ventas-detalladas-por-usuario',
        name: 'reportes-ventas-detalladas-por-usuario',
        component: () => import('@/modules/reportes/ventas/layouts/ReporteVentasDetalladasPorUsuarioLayout.vue'),
        meta: {
            slug: 'reportes_ventas-ver',
        },
    },
    {
        path: '/reportes/ventas/ventas-detalladas-por-cliente',
        name: 'reportes-ventas-detalladas-por-cliente',
        component: () => import('@/modules/reportes/ventas/layouts/ReporteVentasDetalladasPorClienteLayout.vue'),
        meta: {
            slug: 'reportes_ventas-ver',
        },
    },
    {
        path: '/reportes/ventas/ventas-totales-mensuales',
        name: 'reportes-ventas-totales-mensuales',
        component: () => import('@/modules/reportes/ventas/layouts/ReporteVentasTotalesMensualesLayout.vue'),
        meta: {
            slug: 'reportes_ventas-ver',
        },
    },
    {
        path: '/reportes/ventas/ventas-por-usuario',
        name: 'reportes-ventas-por-usuario',
        component: () => import('@/modules/reportes/ventas/layouts/ReporteVentasDetalladasPorUsuarioLayout.vue'),
        meta: {
            slug: 'reportes_usuario-ver',
        },
    },
    {
        path: '/reportes/ventas/ventas-por-area',
        name: 'reportes-ventas-por-area',
        component: () => import('@/modules/reportes/ventas/layouts/ReporteVentasPorAreaLayout.vue'),
        meta: {
            slug: 'reportes_ventas-ver',
        },
    },
]