export default [
  {
    path: "/configuracion/general",
    name: "configuracion-general",
    component: () =>
      import("@/modules/configuracion/general/layouts/GeneralLayout.vue"),
    // import("@/modules/configuracion/general/components/ModalFormEmpresa.vue"),
    meta: {
      slug: "configuracion-ver",
    },
  },
];
