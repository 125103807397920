export default [
  {
    path: "/administracion/promociones",
    name: "administracion-promociones",
    component: () =>
      import("@/modules/administracion/promocion/layouts/PromocionLayout.vue"),
    meta: {
      slug: "promocion-ver",
    },
  },
];
