import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// route de articulos
// administracion
import administracionProductoRouter from '../modules/administracion/producto/router'
import administracionCategoriaRouter from '../modules/administracion/categoria/router'
import administracionMarcaRouter from '../modules/administracion/marca/router'
import administracionUbicacionRouter from '../modules/administracion/ubicacion/router'
import administracionAjusteRouter from '../modules/administracion/ajuste/router'
import administracionClienteRouter from '../modules/administracion/cliente_proveedor/router'
import administracionPromocionRouter from '../modules/administracion/promocion/router'
// punto de venta
// import puntoVentaRouter from '../modules/punto-venta/router'
import ventaRouter from '../modules/venta/router'
import compraRouter from '../modules/compra/router'
// facturas
import facturaRouter from '../modules/factura/router'
// CAJAS
import cajaRouter from '../modules/caja/router'
// cotizaciones
import cotizacionRouter from '../modules/cotizacion/router'
// pedidos
import pedidoRouter from '../modules/pedido/router'
// transferencias
import transferenciaRouter from '../modules/recepcion-transferencia/router'
// configuracion
import configuracionGeneralRouter from '../modules/configuracion/general/router'
import configuracionEmpresaRouter from '../modules/configuracion/empresa/router'
import configuracionSucursalRouter from '../modules/configuracion/sucursal/router'
import configuracionRolRouter from '../modules/configuracion/roles/router'
import configuracionUsuarioRouter from '../modules/configuracion/usuario/router'
import configuracionCargoRouter from '../modules/configuracion/cargo/router'
import configuracionSedeRouter from '../modules/configuracion/sede/router'
import bancoRouter from '../modules/configuracion/banco/router'
import tipoDocumentoRouter from '../modules/configuracion/tipo-documento/router'
import pagoRouter from '../modules/pago/router'
// SIM
import configuracionSinRouter from '../modules/configuracion-sin/router'
// INVENTARIO INICIAL
import inventarioInicialRouter from '../modules/inventario_inicial/router'
import ajustePositivoRouter from '../modules/ajuste_negativo/router'
import ajusteNegativoRouter from '../modules/ajuste_positivo/router'
// REPORTES
import reportesInventarioRouter from '../modules/reportes/inventario/router'
import reportesComprasRouter from '../modules/reportes/compras/router'
import reportesVentasRouter from '../modules/reportes/ventas/router'
import reportesUtilidadRouter from '../modules/reportes/utilidad/router'
import clasificadorRouter from '../modules/configuracion/clasificador/router'


Vue.use(VueRouter)
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'login' }
    },
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/escritorio',
      name: 'escritorio',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
    },
    // ROUTERS DE LOS MODULOS
    // administracion
    ...administracionProductoRouter,
    ...administracionCategoriaRouter,
    ...administracionMarcaRouter,
    ...administracionUbicacionRouter,
    ...administracionClienteRouter,
    ...administracionAjusteRouter,
    ...administracionPromocionRouter,
    // punto de venta
    // ...puntoVentaRouter,
    ...ventaRouter,
    ...compraRouter,
    // facturas
    ...facturaRouter,
    // cotizaciones
    ...cotizacionRouter,
    // pedidos
    ...pedidoRouter,
    // transferencias
    ...transferenciaRouter,
    // configuracion
    ...configuracionGeneralRouter,
    ...configuracionSinRouter,
    ...configuracionEmpresaRouter,
    ...configuracionSucursalRouter,
    ...configuracionRolRouter,
    ...configuracionUsuarioRouter,
    ...configuracionCargoRouter,
    ...configuracionSedeRouter,
    ...cajaRouter,
    ...bancoRouter,
    ...tipoDocumentoRouter,
    ...pagoRouter,
    ...inventarioInicialRouter,
    ...clasificadorRouter,
    // reportes
    ...reportesInventarioRouter,
    ...reportesComprasRouter,
    ...reportesVentasRouter,
    ...reportesUtilidadRouter,
    // ajustes
    ...ajustePositivoRouter,
    ...ajusteNegativoRouter,
    {
      path: '/factura',
      name: 'factura-cuf',
      component: () => import('@/views/FacturaCuf.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/account-desactive',
      name: 'account-desactive',
      component: () => import('@/views/AccountDesactive.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// COMENTADO POR WILL
router.beforeEach((to, _, next) => {
  const navigate = store.getters['auth/authenticated'];
  if (to.name == 'login') {
    if (localStorage.getItem('token')) {
      if (!navigate.esta_activo) {
        return next({
          name: 'account-desactive'
        })
      }
      return next({
        name: 'escritorio'
      })
    }
  }
  if (!localStorage.getItem('token')) {
    if (to.name != 'login') {
      return next({
        name: 'login'
      })
    }
  }
  if (localStorage.getItem('token')) {
    if (!navigate.esta_activo) {
      if (to.name != 'account-desactive') {
        return next({
          name: 'account-desactive'
        })
      }
    }
    else if (navigate.password_reset) {
      if (to.name != 'reset-password') {
        return next({
          name: 'reset-password'
        })
      }
    }
    else if (to.name == 'account-desactive') {
      return next({
        name: 'escritorio'
      })
    }
  }
  if (to.meta.slug) {
    if (!(navigate.permisos.indexOf(to.meta.slug) !== -1)) {
      return next({
        name: 'not-authorized'
      })
    }
  }
  return next()
})
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
