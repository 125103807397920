export default[
  {
    path: '/ajuste-positivo',
    name: 'ajuste-positivo',
    component: () => import('@/modules/ajuste_positivo/layouts/AjustePositivoLayout.vue'),
    meta: {
      slug: 'ajuste_positivo-ver',
    },
  },
  {
    path: '/ajuste-positivo/nuevo',
    name: 'ajuste-positivo-nuevo',
    component: () => import('@/modules/ajuste_positivo/layouts/NuevoAjustePositivoLayout.vue'),
    
    meta: {
      slug: 'ajuste_positivo-registrar',
    },
},
]