export default[
  {
      path: '/pagos/cuentas-por-cobrar',
      name: 'pagos.cuenta-cobrar',
      component: () => import('@/modules/pago/layouts/PagoCuentaCobrarLayout.vue'),
      meta: {
        slug: 'cuentas_cobrar-ver',
      },
  },
  {
    path: '/pagos/cuentas-por-cobrar/cliente/:id',
    name: 'pagos.cuenta-cobrar-cliente',
    component: () => import('@/modules/pago/layouts/DetalleCuentaLayout.vue'),
    meta: {
      slug: 'cuentas_cobrar-ver',
      navActiveLink: 'pagos.cuenta-cobrar',
    },
  },

  // CUENTAS POR PAGAR 

  {
      path: '/pagos/cuentas-por-pagar',
      name: 'pagos.cuenta-pagar',
      component: () => import('@/modules/pago/layouts/PagoCuentaPagarLayout.vue'),
      meta: {
        slug: 'cuentas_pagar-ver',
      },
  },
  {
    path: '/pagos/cuentas-por-pagar/proveedor/:id',
    name: 'pagos.cuenta-pagar-proveedor',
    component: () => import('@/modules/pago/layouts/DetalleCuentaPagarLayout.vue'),
    meta: {
      slug: 'cuentas_pagar-ver',
      navActiveLink: 'pagos.cuenta-pagar',
    },
  },
]