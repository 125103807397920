export default[
    {
      path: '/inventario-inicial',
      name: 'inventario-inicial',
      component: () => import('@/modules/inventario_inicial/layouts/InventarioInicialLayout.vue'),
      meta: {
        slug: 'inventario_inicial-ver',
      },
    },
    {
      path: '/inventario-inicial/nuevo',
      name: 'inventario-inicial-nuevo',
      component: () => import('@/modules/inventario_inicial/layouts/NuevoInventarioIncialLayout.vue'),
      
      meta: {
        slug: 'inventario_inicial-registrar',
      },
  },
]