export default [
  {
      path: '/configuracion-sin/sincronizar',
      name: 'configuracion-sin-sincronizar',
      component: () => import('@/modules/configuracion-sin/layouts/SincronizacionLayout.vue'),
      meta: {
        slug: 'sucursal-ver',
      },
  },
  // {
  //   path: '/configuracion-sin/eventos-significativos',
  //   name: 'configuracion-sin-eventos-significativos',
  //   component: () => import('@/modules/configuracion-sin/layouts/EventosContingencia.vue'),
  //   meta: {
  //     slug: 'sucursal-ver',
  //   },
  // },
  {
    // path: '/configuracion-sin/metodo-pagos',
    // name: 'configuracion-sin-metodo-pagos',
    path: '/configuracion/metodo-pagos',
    name: 'configuracion-metodo-pagos',
    component: () => import('@/modules/configuracion-sin/layouts/MetodoPagoLayout.vue'),
    meta: {
      slug: 'metodo_pago-ver',
    },
  },
  {
    // path: '/configuracion-sin/unidades',
    // name: 'configuracion-sin-unidades',
    path: '/configuracion/unidades',
    name: 'configuracion-unidades',
    component: () => import('@/modules/configuracion-sin/layouts/UnidadLayout.vue'),
    meta: {
      slug: 'unidad-ver',
    },
  },
]