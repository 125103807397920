export default [
    {
        path: '/reportes/utilidad',
        name: 'reportes-utilidad',
        component: () => import('@/modules/reportes/utilidad/layouts/UtilidadLayout.vue'),
        meta: {
            slug: 'reporte_utilidad-ver',
        },
    },
]
