export default[
    {
        path: '/facturas',
        name: 'facturas',
        component: () => import('@/modules/factura/layouts/FacturaLayout.vue'),
        meta: {
          slug: 'factura-ver',
        },
    },
    {
      path: '/facturas/nueva-factura',
      name: 'facturas-nueva-factura',
      component: () => import('@/modules/factura/layouts/NuevaFacturaLayout.vue'),
      meta: {
        slug: 'factura-registrar',
      },
  },
]