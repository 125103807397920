import VuePdfApp from "vue-pdf-app";
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'


require('@/store/auth/subscriber')

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)


//vue validator esp
import { localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es'
localize('es', es)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import Permissions from './mixins/Permissions';
Vue.mixin(Permissions);

Vue.config.productionTip = false
Vue.component("vue-pdf-app", VuePdfApp);

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')
